<template>
  <div class="admin-content">
    <modal
      id="project-settings"
      v-if="showSettings"
      @close="showSettings = false"
    >
      <template v-slot:header>
        <h1>Project Settings</h1>
      </template>
      <template v-slot:body>
        <form @submit.prevent="saveProject">
          <div id="project-details">
            <div class="error" v-if="error">{{ error }}</div>
            <label>Project name</label>
            <input
              name="name"
              :value="project.name"
              @input="save"
              @focusout="saveProject"
            />
            <label>Front page title</label>
            <input
              name="frontPageTitle"
              :value="project.frontPageTitle"
              @input="save"
              @focusout="saveProject"
            />
            <label>Description</label>
            <textarea
              name="description"
              :value="project.description"
              @input="save"
              @focusout="saveProject"
            ></textarea>
            <label>URL slug</label>
            <input
              v-model="project.slug"
              @input="save"
              @focusout="saveProject"
            />

            <div class="fieldset">
              <a
                v-if="!site"
                class="add"
                href="#"
                @mousedown.prevent.stop="editLocation"
                >Add location</a
              >
              <a v-if="site" href="#" @mousedown.prevent.stop="editLocation"
                >Edit location</a
              >
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footer></template>
    </modal>

    <modal
      id="import-from-project"
      v-if="showImport"
      @close="showImport = false"
    >
      <template v-slot:header>
        <h1>Import from another project</h1>
      </template>
      <template v-slot:body>
        <ImportFromProject :project="project" @finish="importFinished" />
      </template>
      <template v-slot:footer />
    </modal>

    <div class="admin-header-menu">
      <h1>Scenarios</h1>
      <div class="buttons">
        <button v-if="isAdmin()" @mousedown.stop.prevent="newScenario">New</button>
        <button v-if="isAdmin()" @mousedown.stop.prevent="showImport = true" class="white">
          Import assets
        </button>
        <button @mousedown.stop.prevent="showSettings = true" class="white">
          Project Settings
        </button>
        <button @mousedown.stop.prevent="breakoutRooms" class="white">
          Rooms
        </button>
      </div>
    </div>

    <div id="scenarios" v-bind:key="hover" class="area-selector-wrapper">
      <a
        :class="'scenario '"
        @mousedown.stop.prevent="editScenario(scenario)"
        href="#"
        v-for="(scenario, index) in scenarios"
        v-bind:key="index"
      >
        <h3 :id="scenario._id">{{ scenario.title }}</h3>
        <modal
          id="delete-scenario-confirm"
          v-if="showDeleteConfirm"
          @close="showDeleteConfirm = false"
        >
          <template>Please confirm deleting scenario</template>
          <template v-slot:body
            >Are you sure you want to delete "{{ scenario.title }}"? This is not
            reversible.</template
          >
          <template v-slot:footer>
            <button @mousedown.stop.prevent="deleteScenario(scenario)">
              Yes, delete it</button
            ><button
              @mousedown.stop.prevent="showDeleteConfirm = false"
              class="white"
            >
              No
            </button>
          </template>
        </modal>
        <edit-buttons
          @edit="editScenario(scenario)"
          @duplicate="duplicateScenario(scenario)"
          @delete="showDeleteConfirm = true"
        />
      </a>
    </div>
    <div id="location" @mousedown.prevent.stop="editLocation">
      <a href="#" class="add" v-if="!this.boundaries">Setup location</a>
      <map-widget
        v-if="boundaries"
        ref="map"
        :id="mapId"
        :defaultZoom="siteZoom"
        :objectTypes="mapObjectTypes"
        :zoomable="true"
        :editable="false"
        :width="window.innerWidth"
        mapboxStyle="mapbox://styles/maupalantir/cl3wrrary000414qxs3zt5pn2"
        :height="668"
        :displayOnly="implementation"
        :boundaries="boundaries"
        :rotate="rotate"
      />
    </div>
  </div>
</template>

<script>
import Modal from "../../components/Modal";
import EditButtons from "../../components/EditButtons";
import ImportFromProject from "../../components/ImportFromProject";
import MapWidget from "../../components/MapWidget";
import { featureCollection } from "@turf/turf";
import { hovered, hoverItem } from "../../helpers";
import debounce from "debounce";
import { zoomToFeature } from "../../mapHelpers";
import { currentUser } from '../../store';

export default {
  components: { Modal, EditButtons, MapWidget, ImportFromProject },
  data: function () {
    return {
      project: null,
      site: null,
      scenarios: [],
      showSettings: false,
      showImport: false,
      showDeleteConfirm: false,
      hover: 0,
      mapId: null,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      siteZoom: 4,
      boundaries: null,
      implementation: featureCollection([]),
      mapObjectTypes: {},
      rotate: 0,
      error: "",
    };
  },
  created: function () {
    this.save = debounce(this.saveProject, 1000);


        this.$store.dispatch("reloadProject").then(() => {
      this.project = this.$store.state.currentProject;
      if (this.$store.state.currentProject.site) {
        this.$store
          .dispatch("loadSite", {
            _id: { $oid: this.$store.state.currentProject.site.toString() },
          })
          .then((site) => {
            this.site = site;
            this.siteZoom = site.siteZoom || 15;
            this.implementation = featureCollection([site.boundaries]);
            this.boundaries = site.boundaries;
            this.mapId = "project-location-map-" + site._id.toString();
          });
      }
      this.$store
        .dispatch("loadScenarios", {
          workshop_id: this.$store.state.currentProject._id.toString(),
        })
        .then((scenarios) => {
          this.scenarios = scenarios;
        });
    });
  },
  mounted: function () {
    if (this.$refs.map) {
      this.$nextTick(() => {
        this.$refs.map.map.on("load", () => {
          this.loadMapObjectTypes();
          zoomToFeature(this.$refs.map.map, this.implementation);
          // this.boundaries = {
          // 	'type': 'Feature',
          // 	'geometry': {
          // 		'type': 'Polygon',
          // 		'coordinates': [
          // 			[
          // 				[-10, 70],
          // 				[38, 70],
          // 				[38,33],
          // 				[-10, 33]
          // 			]
          // 		]
          // 	}
          // }
        });
      });
    }
  },
  methods: {
    isAdmin() {
      return currentUser().customData.admin
    },
    hovered(id) {
      return hovered(id);
    },
    hoverItem(id) {
      hoverItem(id);
      this.hover += 1;
      this.integratedHover += 1;
    },
    loadMapObjectTypes() {
      this.mapObjectTypes["__boundary"] = {
        _id: "__boundary",
        name: "Site boundary",
        object: {
          type: "Polygon",
          icon: "🗺️",
          options: {
            color: "#000000",
            fill: "false",
          },
        },
      };
      // this.$refs.map.addObjectModes();
    },

    editScenario(scenario) {
      this.$store.commit("setScenario", scenario);
      this.$router.push({
        path: "/Admin/Scenario",
      });
    },
    breakoutRooms() {
      this.$router.push({
        path: "/Admin/BreakoutRooms",
      });
    },
    deleteScenario(scenario) {
      this.$store.dispatch("deleteScenario", scenario).then(() => {
        delete this.scenarios[scenario._id];
        this.showDeleteConfirm = false;
      });
    },
    newScenario() {
      const scenario = {
        title: "Untitled scenario",
        nbsTypes: Object.keys(this.$store.state.currentProject.nbsTypes),
      };
      if (this.$store.state.currentProject.site) {
        scenario.site = this.$store.state.currentProject.site;
      }
      scenario.workshop_id = this.$store.state.currentProject._id.toString();
      scenario.created = new Date().getTime();
      this.$store.dispatch("createScenario", scenario).then(() => {
        this.$store.commit("setScenario", scenario);
        this.$router.push({
          path: "/Admin/Scenario",
        });
      });
    },
    editLocation() {
      this.$router.push({
        path: "/Admin/ProjectLocation",
      });
    },
    saveProject(event) {
      // this.$store.dispatch('saveProject', this.project)
      const field = event.target.name
      this.project[field] = event.target.value

      this.$store
        .dispatch("updateProjectKey", {
          project: this.project,
          method: "$set",
          key: field,
          data: this.project[field],
        })
        .catch((e) => {
          this.error = `Unable to save project ${field}: ${e}`;
        });
    },
    importFinished() {
      this.showImport = false;
      this.$store.dispatch("reloadProject").then(() => {
        this.project = this.$store.state.currentProject;
        this.$store
          .dispatch("loadScenarios", {
            workshop_id: this.$store.state.currentProject._id.toString(),
          })
          .then((scenarios) => {
            this.scenarios = scenarios;
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/admin.scss";

.admin-content {
  #scenarios {
    grid: auto / auto auto;
    a:hover,
    a:link,
    a:active,
    a:visited {
      color: $color-base;
    }
  }
  .scenario {
    @include area-selector;
    width: 454px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  #location {
    border-radius: $border-radius;
    overflow: hidden;
    margin-top: 40px;
  }
  #project-settings.modal-container {
    position: absolute;
    width: 781px;
    height: 100%;
    right: 0px;
    top: 0px;
    padding: 42px 120px;
    .modal-header {
      display: flex;
      justify-content: space-between;
    }
  }
  #project-details {
    input,
    textarea {
      width: 700px;
    }
  }
}
</style>
