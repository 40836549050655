<template>
  <div>
    <form>
      <div>
        <label>Select project</label>
        <select v-if="projects" v-model="importProject" @change="loadAssets">
          <option v-for="p in projects" v-bind:key="p._id" :value="p">
            {{ p.name }}
          </option>
        </select>

        <div v-if="importProject">
          <h2>Assets to import</h2>
          <div id="importAssets">
            <div>
              <label>Scenarios</label>
              <div v-for="sc in projectScenarios" v-bind:key="sc._id">
                <label :for="sc._id"
                  ><input
                    type="checkbox"
                    :value="sc"
                    v-model="importScenarios"
                    :name="sc._id"
                  />{{ sc.title }}</label
                >
              </div>
            </div>

            <div>
              <label>NBS Types</label>
              <div id="nbsTypeOptions">
                <label
                  ><input
                    type="checkbox"
                    value="__all"
                    v-model="importNbsTypes"
                  />Import all</label
                >
              </div>
            </div>
          </div>

          <div>
            <button
              type="button"
              @mousedown.prevent.stop="importAssets"
              v-if="importProcesses < 1"
            >
              Import
            </button>
            <div v-if="importProcesses > 0">Importing...</div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
const cloneDeep = require("lodash/cloneDeep");

export default {
  props: {
    project: Object,
  },
  data: function () {
    return {
      projects: null,
      importProject: null,
      projectScenarios: null,
      importScenarios: [],
      importNbsTypes: [],
      importProcesses: 0,
    };
  },
  created: function () {
    this.$store
      .dispatch("loadProjects")
      .then((projects) => {
        this.projects = projects.filter((p) => p._id !== this.project._id);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    loadAssets() {
      this.$store
        .dispatch("loadScenarioOptions", this.importProject._id.toString())
        .then((scenarios) => {
          this.projectScenarios = scenarios;
        });

      this.projectNbsTypes = this.importProject.nbsTypesRaw;
    },
    importAssets() {
      this.importProcesses = this.importScenarios.length;

      this.importScenarios.forEach((sc) => {
        this.$store.dispatch("loadScenario", sc._id).then((scenarioDetails) => {
          const newScenario = cloneDeep(scenarioDetails);
          delete newScenario._id;
          newScenario.workshop_id = this.project._id;
          newScenario.site = this.project.site;
          // Copy workshop indicators
          this.$store.dispatch("createScenario", newScenario).then(() => {
            const indicatorsToCopy = [];
            if (newScenario.indicators) {
              for (const indicator in newScenario.indicators) {
                const existingIndicator = this.project.indicators
                  ? this.project.indicators[indicator]
                  : false;
                if (!existingIndicator) {
                  indicatorsToCopy.push(
                    this.importProject.indicators.find(
                      (i) => i._id === indicator
                    )
                  );
                }
              }
            }

            if (indicatorsToCopy.length > 1) {
              this.$store
                .dispatch("updateProjectKey", {
                  project: this.$store.state.currentProject,
                  key: "indicators",
                  method: "$push",
                  data: { $each: indicatorsToCopy },
                })
                .then(() => {
                  this.importProcessFinished();
                });
            } else {
              this.importProcessFinished();
            }
          });
        });
      });

      if (this.importNbsTypes.length > 0) {
        this.importProcesses += 1;
        if (this.importNbsTypes.includes("__all")) {
          this.importNbsTypes = this.importProject.nbsTypesRaw;
        }
        const typesToClone = [];
        const typesToUpdate = [];
        this.importNbsTypes.forEach((nbsType) => {
          const existingType = this.project.nbsTypes[nbsType._id];
          const index = this.project.nbsTypesRaw.indexOf(existingType);
          if (!existingType) {
            typesToClone.push(cloneDeep(nbsType));
          } else {
            // Update existing NBS type with impact on indicators from imported type
            const diff = Object.keys(nbsType.impact).filter(
              (indicator) =>
                !Object.keys(existingType.impact).includes(indicator)
            );

            if (diff.length > 0) {
              const newType = cloneDeep(existingType);
              diff.forEach((indicator) => {
                newType.impact[indicator] = nbsType.impact[indicator];
              });
              typesToUpdate.push({ id: index, data: newType });
            }
          }
        });
        if (typesToUpdate.length > 1) {
          this.importProcesses += 1;
          const payload = {
            project: this.project,
            types: typesToUpdate,
          };
          this.$store.dispatch("saveNbsType", payload).then(() => {
            this.importProcessFinished();
          });
        }
        if (typesToClone.length > 1) {
          this.$store
            .dispatch("updateProjectKey", {
              project: this.$store.state.currentProject,
              key: "nbsTypes",
              method: "$push",
              data: { $each: typesToClone },
            })
            .then(() => {
              this.importProcessFinished();
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          this.importProcessFinished();
        }
      }
    },
    importProcessFinished() {
      this.importProcesses -= 1;
      if (this.importProcesses < 1) {
        this.$emit("finish");
      }
    },
  },
};
</script>
<style>
#import-from-project {
  width: 500px;
}
</style>
